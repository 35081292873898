
import api from '@/services/api'
import { signalr } from '@/main.js'

export default {
    whoAmI(){
        return api().get('User/WhoAmI');
    },
    pingAuth(){
        return api().get('User/PingAuth')
    },
    createUser(data){
        return api().post('User', data);
    },
    editUser(id,data){
        return api().put('User/' + id, data);
    },
    deleteUser(id){
        return api().delete('User/' + id);
    },
    logout(data){
        signalr.disconnect();
        return api().post('User/Logout', data);
    },
    //Check user settings
    getUserSetting(query){
        return api().get('User/GetUserSetting?setting=' + query);
    },
    //subscribe/unsubscribe to email alerts
    setUserSetting(data){
        return api().post('User/SetUserSetting', data);
    },
    getUserBookmarks(includeArchived = false){
        return api().get('User/GetUserBookmarks?includeArchived=' + includeArchived);
    },
    setUserBookmarks(bookmarkList, includeArchived = false) {
        if(bookmarkList.length == 0){
            bookmarkList = '-1';
        }
        return api().post('User/SetUserBookmarks?bookmarkList=' + bookmarkList);
    },
    //reset password from  profile
    passwordReset(data){
        return api().put('User/PasswordReset', data);
    },
    //Get all  Contact cards
    getUsers(){
        return api().get('User/ContactCards');
    },
    //Edit Contact Card
    editContactCard(data){
        return api().put('User/UpdateContactCard', data);
    },    
    //Create contact card
    createContactCard(data){
        return api().post('User/AddContactCard', data);
    },
    //SearchContact cards
    searchContactCards(query){
        return api().get('User/ContactCards?searchString=' + query);
    },
    //SearchContact cards by stakeholderId
    searchContactCardsById(id){
        return api().get('User/ContactCards?stakeholderId=' + id);
    },
    //Create a user record for the contact card specified
    createUserFromContactCard(data){
        return api().post('User', data);
    },

    rightToBeForgotten() {
        return api().get('User/RightToBeForgotten');
    },

    getLiveUsers() {
        return api().get('User/GetLiveUsers');
    },
    assumeRole(assumeRoleId) {
        return api().get('User/AssumeRole?roleId=' + assumeRoleId);
    },
    getAssumedRole() {
        return api().get('User/IsRoleAssumed');
    }
}