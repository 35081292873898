// Vue
import Vue from 'vue'
import App from './App.vue'
// SIGNALR BLOCK //
import SignalRConnection from '@/services/SignalRConnection';

import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '30d'})

// Router
import VueRouter from 'vue-router'
import router from './router'

// Store
import store from './store'

// Vuetify
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'

import 'regenerator-runtime/runtime';

// VeeValidate
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';

import babelPolyfill from 'babel-polyfill'

// Required
extend('required', {
    ...required,
    message: 'This field is required'
  },
)
// Min
extend('min', {
    validate(value, args) {
      return Number(value) >=  Number(args.length)
    },
    params: ['length'],
    message: 'The field must be greater than {length}'
  }
)
// Max
extend('max', {
    validate(value, args) {
      return Number(value) <= Number(args.length);
    },
    params: ['length'],
    message: 'The field must be less than {length}'
  }
)
// Positive
extend('positive', {
  validate(value) {
    return  Number(value) >= 0
  },
  message: 'The field must be a positive number'
})
// Number
extend('number', {
  validate(value) {
    return /\d/.test(value)
  },
  message: 'The field must be a number'
})
// Date
extend('date', {
  validate(value, args) {
    console.log('validate date');
    const result = moment(value, args.format, true)
    return (result._isValid)
  },
  params: ['format'],
  message: 'The field must be formatted {format}'
})
// Variable
extend('dateOrVariable', {
  validate(value, args) {
    const resultDateCheck = moment(value, args.format, true)
    const resultVariableCheck = /(.*)({(.*)})(.*)/.test(value)
    console.log('validate variable', resultDateCheck, resultVariableCheck, value, args.format)
    return resultDateCheck._isValid || resultVariableCheck 
  },
  params: ['format'],
  message: 'The field must be formatted as {format} or a variable {VARIABLE}'
})

// Html canvas
import VueHtml2Canvas from 'vue-html2canvas'

import './plugins/theme'
import './plugins/chartist'
import './plugins/vee-validate'

// i18n
import i18n from './i18n'

// Leaflet
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

// Loadash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

// Apex Charts
import VueApexCharts from 'vue-apexcharts'

// Market Cluster
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

// Leaflet Plugin
import LeafletPlugin from './plugins/leafletPlugin.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'

// Vue Draggable Resizable
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
Vue.component('vue-draggable-resizable', VueDraggableResizable);


// leaflet
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster)
Vue.use(LeafletPlugin)
// Loadash
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

// Easel
Vue.use(require('vue-easeljs'))


// Apex Charts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Router
Vue.use(VueRouter)

// Html canvas
Vue.use(VueHtml2Canvas)

// Production Tip
Vue.config.productionTip = false

// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})

// Production Tip
Vue.config.productionTip = false

// SIGNALR BLOCK //
let signalr = new SignalRConnection(process.env.VUE_APP_SIGNALR_HUB); // Replace with the actual hub URL



export { signalr };

new Vue({
  router,
  store,
  vuetify,
  iconfont: 'md',
  ValidationProvider,
  i18n,
  render: h => h(App),
}).$mount('#app');